import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import UIComponent from './component/UIComponent';
import { Socket } from 'socket.io-client'; // Import the Socket type
import io from 'socket.io-client';
import { EmailComponent } from './component/EmailComponent';
import 'react-toastify/dist/ReactToastify.css';
import { createSession } from './utility/utils';



function App() {

  // const socket: Socket = io.connect('http://localhost:8083'); // Replace with your server URL
  const [sessionId,setSessionId] = useState<string>("");

  useEffect(() => {

    // Clean up on unmount
    const localSessionId = localStorage.getItem('chatSessionId');
    const handleCreateSession = async () => {
      if (localSessionId) {
        setSessionId(localSessionId)
      } else {
        let authData: any = localStorage.getItem('auth');
        if (authData) {
            authData = JSON.parse(authData)
        } else {
            //authData = {email: 'mss.ujjwal@gmail.com'}
        }
    
        let payload = {
          email:  authData?.email
        }
        // let resp: any = await createSession(payload)
        // if(resp.status) {
        //   let sessionId = resp.data.session_id
        //   localStorage.setItem('chatSessionId', sessionId.toString());
        //   localStorage.setItem('chatEmail', authData?.email);
        //   setSessionId(sessionId)
        // }
      }
    }
    handleCreateSession()
    return () => {
      // socket.disconnect();
    };
  },[]);
  

  return (
    <>
     {sessionId ? <UIComponent /> : <EmailComponent sendData={setSessionId}/>}
     {/* {sessionId && <UIComponent />} */}
    </>
  );
}

export default App;
