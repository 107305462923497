import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import "./main.css"
import {socket} from "./../socket"
import { MessageType, getAnswer, getHistory, userType } from "./../utility/utils";
import { Message } from "./Message";
import { MessagesUI } from "./MessagesUi";
import WaitingLoader from "./WaitingLoader";

const UIComponent :React.FC =() => {

    let authData: any = localStorage.getItem('auth');
    if (authData) {
        authData = JSON.parse(authData)
    }
    
    const [messages,setMessages] = useState<MessageType[]>([
        {
            "user": userType.server,
            "message": `Hello,${authData ? authData?.username: ''} How can I assist you?`,
            "timestamp": new Date().getTime(),
            "isAnswer": false
        }
    ]);
    const [inputMessage,setInputMessage] = useState<string>("");
    const [chatHistory,setChatHistory] = useState<any>([]);

    const [isLoading,setLoading] = useState<boolean>(true);
    const [msgtimeout,setMsgtimeout] = useState<any>();
    const [chattimeout,setChattimeout] = useState<any>();
    const [isIdle, setIsIdle] = useState<boolean>(false);
    const [isChatEnd, setIsChatEnd] = useState<boolean>(false);

    const inputRef = useRef<any>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const localSessionId = localStorage.getItem('chatSessionId');

    const handleSubmit = async (event : any ="") => {
        event?.preventDefault()
        setIsIdle(false)
        setIsChatEnd(false)
        clearTimeout(msgtimeout);
        clearTimeout(chattimeout);

        if (!inputRef.current.value && !inputMessage) {
            return
        }
        let message = {
            user : userType.client,
            message : inputRef.current.value || inputMessage,
            timestamp : new Date().getTime(),
            isAnswer: true
        }
        setMessages((oldState)=>([...oldState,message]))
       
        setLoading(true)
        let payload = {
            "question": inputMessage || inputRef.current.value,
            "sessionId": localSessionId,
            "chatHistory": chatHistory
        }
        
        setTimeout(function() {
            setInputMessage("")     
        },200)

        let resp: any = await getAnswer(payload);

        let answer = '';
        if (resp.status) {
            setLoading(false)

            answer = resp.data.answer
            let reply = {
                user : userType.server,
                message : answer,
                timestamp : new Date().getTime(),
                isAnswer: true
            }
            setMessages((oldState)=>([...oldState,reply]))
            
            setChatHistory(resp.data.history)
            setTimeout(() => {
                inputRef.current.focus();
            },100)

            
            // inputElement.focus();
            
            // if (!isIdle) {
                handleIdle()
            // }
            // if(!isChatEnd) {
                handleChatEnd()
            // }
    

        } else {
            setLoading(false)
        }
    }

    const handleInput = (event : any)  => {
        clearTimeout(msgtimeout);
        clearTimeout(chattimeout);
        if (!isIdle) {
            handleIdle()
        }
        if(!isChatEnd) {
            handleChatEnd()
        }

        let value = event.currentTarget.value;
      
        setInputMessage(value);      
    }

    useEffect(() => {
        const fetchData = async () => {
            let historyData:any = await getHistory({sessionId: localSessionId})
            if (historyData.status) {
                let history = historyData.data.history
                const formatedHistory: any[][] = [];
                let currentUserMessage: any = null;
               
                for (const item of history) {
                    if (item.type === "user") {
                      currentUserMessage = item.message
                      
                    } else if (item.type === "ai" && currentUserMessage) {
                      formatedHistory.push([currentUserMessage, item.message]);
                    }
                }
                  
                setChatHistory([...formatedHistory])
                
                // For loading history
                // loadHistoryChat(history);
    
            }
            setLoading(false)
        }
        
        fetchData();

        handleIdle();
        handleChatEnd();
        return () => {
            clearTimeout(msgtimeout);
        }

    },[])


    const loadHistoryChat = async (history: { type: string; message: any; }[]) => {
        history.forEach((item: { type: string; message: any; }) => {
            let message = {
                user : item.type == 'user' ? 'client' : 'server',
                message : item.message,
                timestamp : new Date().getTime(),
                isAnswer: true
            }
            setMessages((oldState: any)=>([...oldState,message]))
        })
    }

    const handleIdle = () => {
        let timeout = setTimeout(() => {
            // if (!isIdle) {
                let message = {
                    user : userType.server,
                    message : 'Hi, Are you there ' + (authData ? authData?.username: '') +'?',
                    timestamp : new Date().getTime(),
                    isAnswer: false
                }
                setMessages((oldState)=>([...oldState,message]))
            // }
            setIsIdle(true)
        },60000)
        setMsgtimeout(timeout);
    };

    const handleChatEnd = () => {
        let timeout = setTimeout(() => {
            let message = {
                user : userType.server,
                message : 'Thank you for responding to the support chat!<br> We are here to help you. Please respond to the support chat if you need any assistance.',
                timestamp : new Date().getTime(),
                isAnswer: false
            }
            setMessages((oldState)=>([...oldState,message]))
            setIsChatEnd(true)
        },120000)
        setChattimeout(timeout)
    }




    useEffect(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            const maxScrollTop = scrollHeight - clientHeight;
            messagesContainerRef.current.scrollTo({
              top: maxScrollTop,
            //   behavior: 'smooth'
            });
        }
    }, [messages]);

    return <>
        <div className="chat-main">
            <div className="message-container" >
                <div className="message-body" ref={messagesContainerRef}>
                    <MessagesUI 
                        messages = {messages}
                    />
               </div>
                { isLoading && <WaitingLoader /> } 
            </div>
            <div className={(isLoading ? 'disable-block ': '') + 'chat-input-container'} >
                <form onSubmit={(event: any) => handleSubmit(event)} >
                    <input 
                        placeholder="Please enter message" 
                        value={inputMessage}
                        ref={inputRef}
                        onChange={handleInput}
                        disabled={isLoading}
                        id="chat-input"
                        autoComplete="off"  
                    />  
                    <div className="submit-block">
                        <button className="submit-btn" disabled={isLoading} >Send</button>
                    </div>
                </form>
            </div>
        </div>
    </>
}
export default UIComponent;