import React, { useEffect, useRef } from "react"
import { MessageType, userType } from "./../utility/utils"
import { Message } from "./Message"

interface MessagesProps {
    messages : MessageType[]
}

export const MessagesUI : React.FC<MessagesProps> = ({ messages }) => {

    const messagesEndRef = useRef<HTMLDivElement>(null)
    return <>
        { messages.map((message : MessageType) => {
            let cls = "sent-message";
            if(message.user === userType.server){
                cls = "received-message";
            }
            return <> 
                {<Message
                    key = {message.timestamp}
                    message = {message}
                    classes = {cls}
                />}
               
                </>
        })}
         </>
}