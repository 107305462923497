import { MessageType } from "./../utility/utils"
import React from "react"
// import icon from '../../assets/rta-icon.png';
import icon from './public/assets/rta-icon.png';

interface MessageProps {
    message: MessageType,
    classes: string
}
declare global {
    interface Window {
      zE: any;
    }
  }
  
export const Message: React.FC<MessageProps> = ({ message, classes }: any) => {
    let u_message = message.message;
    // u_message = u_message.replace("\n", "<br/>")
    const iconUrl = 'https://taylorrobinsonmusic.s3.amazonaws.com/chatbot/assets/rta-icon.png';
    const userEmail = localStorage.getItem('chatEmail');
    let userLoginData: any = localStorage.getItem('auth');
    if (userLoginData) {
        userLoginData = JSON.parse(userLoginData)
    }
    
    const openZendesk= ()  => {
    
    }

    return (<div className={"user-message-box " + classes}>
        <div className="msg-img">
            {message.user == 'server' ? <img src={iconUrl} /> : <p className="user-letter">{userEmail?.charAt(0)}</p>}
        </div>
        <div key={message.timestamp} className={"user-message " + classes} >
            <div  dangerouslySetInnerHTML={{__html: u_message}} />
            {message.isAnswer && message.user == 'server' && <div><p>If this problem persists please <span className="chat-support" onClick={openZendesk}>contact support</span></p></div>}
        </div>
    </div>)
}