import axios from "axios";

// const main_url = 'http://localhost:8083';
const main_url = 'http://34.210.104.97';

export interface MessageType {
    user : userType,
    message : string,
    timestamp : number | string,
    isAnswer: boolean
}

export enum userType {
    client = "client",
    server = "server"
}

export const getHistory = (data: any) => {
    return axios({
        method: 'POST',
        url: main_url + "/get-history",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // You can specify specific origins here too
          },    
        data
    })
    .then((res) => {
        if (res.data) {
            return {
                status: 1,
                data: res.data
            }
        } else {
            return {
                status: 0,
            }
        }
    }).catch((error) => {
        console.log('error',error.message)
        return {
            status: 0
        }
    })
}


export const getAnswer = (data: any) =>{
    return axios({
        method: 'POST',
        url: main_url + "/webchat",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // You can specify specific origins here too
          },    
        data
    })
    .then((res) => {
        if (res.data) {
            return {
                status: 1,
                data: res.data
            }
        } else {
            return {
                status: 0,
            }
        }
    }).catch((error) => {
        console.log('error',error.message)
        return {
            status: 0
        }
    })
}

export const createSession = (data: any) => {
    return axios({
        method: 'POST',
        url: main_url + "/register-user",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // You can specify specific origins here too
          },    
        data
    })
    .then((res) => {
        console.log('res =>',res)

        if (res.data) {
            return {
                status: 1,
                data: res.data
            }
        } else {
            return {
                status: 0,
            }
        }
    }).catch((error) => {
        console.log('error',error.message)
        return {
            status: 0
        }
    })
}
