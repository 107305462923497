import React from "react"

const WaitingLoader: React.FC = () => {
    return (
        <div className="is-typing-outer">
            <h2>Typing</h2>
            <div className="is-typing">
                <div className="jump1"></div>
                <div className="jump2"></div>
                <div className="jump3"></div>
                <div className="jump4"></div>
            </div>
        </div>
    )
}

export default WaitingLoader;