
import React, { useEffect,useState, useRef } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createSession } from "./../utility/utils";


export const EmailComponent: React.FC<any> = (props) => {

    const [inputEmail,setInputEmail] = useState<string>("");
    const emailRegexPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = (email: string): boolean => {
        return emailRegexPattern.test(email);
      };

    const handleInput = async (event : string|any ="") => {
        setInputEmail(event.target.value)
    }

    const handleSubmit = async (event : string|any ="") => {
        let email = inputEmail || event.target.value
        if(!email && email.trim() == '') {
            toast.error('Please enter your email!', {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        } else {
            if(!isValidEmail(email)) {
                toast.error('Please enter your valid email!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                return
            }
            let payload = {
               email:  email
            }
            let resp: any = await createSession(payload)
            if(resp.status) {
                let sessionId = resp.data.session_id
                localStorage.setItem('chatSessionId', sessionId.toString());
                localStorage.setItem('chatEmail', email);
                props.sendData(sessionId)
            }
        }
    }



    return <>
        <div className="email-page">
            <div className="email-container">
                <h2>Please enter your email</h2>
                <input type="text" onChange={handleInput} value={inputEmail} placeholder="Email"/>
                <button className="submit-btn" onClick={handleSubmit}>Send</button>
            </div>
        </div>
        <ToastContainer />
    </>
}